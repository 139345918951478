import * as actionTypes from './memberships-constants'

export const membershipsInitialState = {
  additionalImagesUrls: [],
  announcementImageUrls: {},
  buyImageUrls: {},
  chatImageUrls: {},
  memberPublicEvents: [],
  activeParticipants: [],
  currentPageDetails: {},
  soldMemberDetails: {},
  currentMembershipDetailsSummary: {},
  isMembershipMember: false,
  isMemberDisabled: false,
}

export const membershipsReducer = (state = membershipsInitialState, action) => {
  const { type, payload } = action
  switch (type) {
    case actionTypes.PAGES_SET_ADDITIONAL_IMAGES:
      return { ...state, additionalImagesUrls: payload }
    case actionTypes.ANNOUNCEMENT_SET_IMAGES:
      return { ...state, announcementImageUrls: payload }
    case actionTypes.BUY_SET_IMAGES:
      return { ...state, buyImageUrls: payload }
    case actionTypes.CHAT_SET_IMAGES:
      return { ...state, chatImageUrls: payload }
    case actionTypes.GET_PAGE_DETAILS:
      return { ...state, currentPageDetails: payload }
    case actionTypes.MEMBERSHIPS_GET_SOLD_MEMBER_DETAILS:
      return { ...state, soldMemberDetails: payload }
    case actionTypes.MEMBERSHIP_GET_DETAILS_SUMMARY:
      return { ...state, currentMembershipDetailsSummary: payload }
    case actionTypes.IS_MEMBERSHIP_MEMBER:
      return { ...state, isMembershipMember: payload }
    case actionTypes.IS_MEMBER_DISABLED:
      return { ...state, isMemberDisabled: payload }
    case actionTypes.EVENTS_GET_MEMBER_PUBLIC_EVENTS:
      return { ...state, memberPublicEvents: payload.events }
    case actionTypes.EVENTS_SET_MEMBER_HOME_PUBLIC_EVENTS:
      return { ...state, memberPublicEvents: payload }
    case actionTypes.EVENTS_SET_ACTIVE_PARTICIPANTS:
      return { ...state, activeParticipants: payload }
    default:
      return state
  }
}
